import React from 'react';
import {Helmet} from 'react-helmet-async';
import {Link as RouterLink} from 'react-router-dom';
import {composePageTitle} from './Common';
import Footer from './components/Footer';
import Header from './components/Header';
import Application from './config/application';
import {PageUrl} from './Constants';

const About = () => {
  return (
    <div id="main-wrapper">
      <Helmet title={composePageTitle('About Us')} />
      <Header />
      <section className="page-header page-header-dark bg-secondary">
        <div className="container">
          <div className="row align-items-center">
            <div className="col-md-8">
              <h1>About Us</h1>
            </div>
            <div className="col-md-4">
              <ul className="breadcrumb justify-content-start justify-content-md-end mb-0">
                <li>
                  <RouterLink to={PageUrl.HOME}>Home</RouterLink>
                </li>
                <li className="active">About Us</li>
              </ul>
            </div>
          </div>
        </div>
      </section>
      <div id="content">
        <div className="container">
          <div className="bg-white shadow-md rounded p-4">
            <h2 className="text-6">Learn More About Us</h2>
            <p>
              {' '}
              Looking for a comprehensive travel experience at budget-friendly
              rates? {Application.ORG_DISPLAY_NAME} is the quickest and the
              easiest way to book flights online at discounted rates. Since the
              time we came into inception, we lead the entire market of flight
              booking. {Application.ORG_DISPLAY_NAME} offers you the best
              control over the travel plans.
            </p>
            <p>
              {Application.ORG_DISPLAY_NAME} is USA's most premier flight
              booking portal that promises to provide you with the finest
              experience across the sky. At {Application.ORG_DISPLAY_NAME}, we
              strongly specialize in delivering travel services, flight booking
              convenience. We have now become the global search engine for
              flight booking at attractive rates. Most of the times it is not
              possible for an individual to book flights online at discounted
              rates and we offer you the chance to get up to 50% discount on
              each flight. We pride our attractive discount deals, extensive
              range of partners, outstanding travel contents to make us the best
              and the natural starting point for the flight search.{' '}
            </p>
            <p>
              {Application.ORG_DISPLAY_NAME} has the entire boatload of data
              relating to airfare and also we offer much superior customer care
              service to make a flight booking less stressful and to let you buy
              tickets at the cheapest rates. Initially, we partnered with
              seasoned channels just to fill the gap and offer attractive deals
              on flight tickets. We have something really wonderful to offer to
              each of our clients.
            </p>
            <div className="row mt-4 mb-2">
              <div className="col-md-4">
                <div className="featured-box style-1">
                  <div className="featured-box-icon text-primary">
                    {' '}
                    <i className="far fa-thumbs-up"></i>
                  </div>
                  <h3>Best Deal Offer</h3>
                  <p>
                    Find our lowest deal to destinations worldwide, guaranteed
                  </p>
                </div>
              </div>
              <div className="col-md-4">
                <div className="featured-box style-1">
                  <div className="featured-box-icon text-primary">
                    {' '}
                    <i className="far fa-paper-plane"></i>{' '}
                  </div>
                  <h3>Easy Booking</h3>
                  <p>
                    Search, select and save - the fastest way to book your trip
                  </p>
                </div>
              </div>
              <div className="col-md-4">
                <div className="featured-box style-1">
                  <div className="featured-box-icon text-primary">
                    {' '}
                    <i className="far fa-eye"></i>{' '}
                  </div>
                  <h3>24/7 Customer Care</h3>
                  <p>
                    Get award-winning service and special deals by calling{' '}
                    {Application.SUPPORT_PHONE}
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <Footer />
    </div>
  );
};

export default About;
