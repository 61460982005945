import React from 'react';
import {Helmet} from 'react-helmet-async';
import {Link as RouterLink} from 'react-router-dom';
import {composePageTitle} from './Common';
import Footer from './components/Footer';
import Header from './components/Header';
import Application from './config/application';
import {PageUrl} from './Constants';

const Contact = () => {
  return (
    <div id="main-wrapper">
      <Helmet title={composePageTitle('Contact Us')} />
      <Header />
      <section className="page-header page-header-dark bg-secondary">
        <div className="container">
          <div className="row align-items-center">
            <div className="col-md-8">
              <h1>Contact Us</h1>
            </div>
            <div className="col-md-4">
              <ul className="breadcrumb justify-content-start justify-content-md-end mb-0">
                <li>
                  <RouterLink to={PageUrl.HOME}>Home</RouterLink>
                </li>
                <li className="active">Contact Us</li>
              </ul>
            </div>
          </div>
        </div>
      </section>
      <div id="content">
        <div className="container">
          <div className="row g-4">
            <div className="col-md-6">
              <div className="bg-white shadow-md rounded p-4">
                <h2 className="text-6 mb-4">Get in touch</h2>
                <hr className="mx-n4 mb-4" />
                <div className="featured-box style-1">
                  <div className="featured-box-icon text-primary">
                    {' '}
                    <i className="fas fa-map-marker-alt"></i>
                  </div>
                  <h3>{Application.ORG_DISPLAY_NAME}</h3>
                  <p>
                    {Application.ORG_ADD_LINE1}{' '}{Application.ORG_ADD_LINE2}
                  </p>
                </div>
                <div className="featured-box style-1">
                  <div className="featured-box-icon text-primary">
                    {' '}
                    <i className="fas fa-phone"></i>{' '}
                  </div>
                  <h3>Telephone</h3>
                  <p>{Application.SUPPORT_PHONE}</p>
                </div>
                <div className="featured-box style-1">
                  <div className="featured-box-icon text-primary">
                    {' '}
                    <i className="fas fa-envelope"></i>{' '}
                  </div>
                  <h3>Business Inquiries</h3>
                  <p>{Application.CONTACT_EMAIL}</p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <Footer />
    </div>
  );
};

export default Contact;
